<template>
  <div id="aboutUs">
    <!-- 头部组件 -->
    <headPage />
    <!-- 轮播图 -->
    <carousel />

    <!--  -->
    <div class="container">
      <div class="title fw-600">关于晟融</div>

      <img class="img" src="../../../assets/loginIndex/about_us_1.png" />

      <div class="text-2">
     北京晟融信息是专业从事在线职业培训与服务平台运营的互联网软件企业。自主研发的安知职业培训平台，为职业化人群提供职业技能、安全生产、职业健康、职业规划、就业社保等全维度服务。公司是双软认定企业，2021年获评北京市百家最具成长性软件企业。公司与政府部门、行业协会以及大型企业建立了广泛的合作，是中国职业健康安全协会秘书长单位。在石家庄、郑州、太原、呼和浩特等市建有分公司。
      </div>

      <div class="text-2">
      河北晟融数据股份有限公司是北京晟融信息技术有限公司全资母公司，2017年在新三板挂牌（代码870733）。 公司位于秦皇岛国家级数据产业园，占地15亩，建筑面积9000平米。目前作为北京晟融的运营基地，系统化的支撑安知职业培训平台的平台建设、运营服务与内容制作。
      </div>
    </div>

    <div class="container-2">
      <div class="title fw-600">互联网在线职业培训平台</div>
      <div class="flex">
        <div class="container-2-item">
          <div>
            <span class="text-1">4</span>
            <span class="text-2">万</span>
          </div>
          <div class="text-3">平台课件总课时</div>
        </div>

        <div class="container-2-item">
          <div>
            <span class="text-1">187</span>
            <span class="text-2">类</span>
          </div>
          <div class="text-3">人社技能工种</div>
        </div>

        <div class="container-2-item">
          <div>
            <span class="text-1">15</span>
            <span class="text-2">个</span>
          </div>
          <div class="text-3">覆盖省份</div>
        </div>

        <div class="container-2-item">
          <div>
            <span class="text-1">2</span>
            <span class="text-2">万</span>
          </div>
          <div class="text-3">注册企业</div>
        </div>

        <div class="container-2-item">
          <div>
            <span class="text-1">270</span>
            <span class="text-2">万</span>
          </div>
          <div class="text-3">学员下载学习</div>
        </div>

        <div class="container-2-item">
          <div>
            <span class="text-1">9</span>
            <span class="text-2">个</span>
          </div>
          <div class="text-3">业务领域</div>
        </div>
      </div>
      <div class="text-4">
        获得人社部、北京、上海、河北、河南、山西、广东、海南、内蒙、吉林、甘肃等多个省份政府部门的权威推荐
      </div>
    </div>

    <div class="container-3">
      <div class="title fw-600">专注民生领域 致力数据服务</div>
      <div class="cent">
        <div class="item">
          <img src="../../../assets/loginIndex/about_us_3.png" />
          <div class="t-1">专业技术服务</div>
          <div class="t-2">技术人员83名 自主研发和运营服务</div>
        </div>

        <div class="item">
          <img src="../../../assets/loginIndex/about_us_4.png" />
          <div class="t-1">大型系统开发</div>
          <div class="t-2">成熟的J2EE平台多个省级系统应用</div>
        </div>
        <div class="item">
          <img src="../../../assets/loginIndex/about_us_5.png" />
          <div class="t-1">全过程数据服务</div>
          <div class="t-2">晟融大数据平台 完整的数据资源体系方案</div>
        </div>
        <div class="item">
          <img src="../../../assets/loginIndex/about_us_6.png" />
          <div class="t-1">职业服务平台</div>
          <div class="t-2">互联网＋大数据 深度融合职业化人群需求</div>
        </div>
      </div>
    </div>

    <div class="container-4">
      <div class="title fw-600">晟融精品项目展示</div>

      <div class="cont-1">
        <div class="item">内蒙古工伤保险系统</div>
        <div class="item">河北省工伤保险系统</div>
        <div class="item">河南省社保数据处理</div>
        <div class="item">河北省发卡数据服务</div>
      </div>
      <div class="cont-1">
        <div class="item">河北省医院结算系统</div>
        <div class="item">幸福秦皇岛数据平台</div>
        <div class="item">内蒙古金保工程系统</div>
        <div class="item">雄安新区企业服务系统</div>
      </div>
    </div>

    <!-- 底部组件 -->
    <bottomPage />
  </div>
</template>

<script>
import headPage from "../../wrongAuthenticatePage/headPage.vue";
import bottomPage from "../../wrongAuthenticatePage/bottomPage.vue";
import carousel from "../../wrongAuthenticatePage/carousel.vue";

export default {
  name: "wrongAuthenticatePage_aboutUs",
  components: {
    headPage,
    bottomPage,
    carousel,
  },
  data() {
    return {
      bannerList: [], // 轮播图列表
    };
  },
  created() {
  },
  methods: {
   
  },
};
</script>

<style lang="less">
#aboutUs {
  height: 100vh;
  overflow-y: scroll;
  background-color: #f8fafd;

  .container {
    padding: 60px 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      color: #3f4652;
      font-size: 34px;
      text-align: center;
    }
    .text-1 {
      width: 1168px;
      text-align: center;
      font-size: 17px;
      margin-top: 16px;
      color: #5c6b84;
    }
    .img {
      margin-top: 36px;
    }
    .text-2 {
      width: 1168px;
      margin-top: 20px;
      text-indent: 30px;
      color: #5c6b84;
      font-size: 17px;
    }
  }

  .container-2 {
    width: 1168px;
    margin: 0 auto;
    .title {
      margin-top: 80px;
      color: #3f4652;
      font-size: 34px;
      text-align: center;
    }
    .flex {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .container-2-item {
        width: 180px;
        height: 140px;
        background-color: #ffffff;
        border-radius: 8px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text-1 {
          font-size: 40px;
          color: #2878ff;
          font-weight: 600;
        }
        .text-2 {
          font-size: 20px;
          color: #2878ff;
        }
        .text-3 {
          font-size: 20px;
          color: #3f4652;
          margin-top: 20px;
        }
      }

      .container-2-item:hover {
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }

    .text-4 {
      font-size: 17px;
      color: #5c6b84;
      margin-top: 20px;
      text-align: center;
    }
  }

  .container-3 {
    width: 100%;
    height: 390px;
    padding: 60px 0;
    box-sizing: border-box;
    margin-top: 60px;
    background-image: url("../../../assets/loginIndex/about_us_2.png");
    background-size: 100% 100%;
    .title {
      color: #ffffff;
      font-size: 34px;
      text-align: center;
    }
    .cent {
      width: 1168px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .item {
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        img {
          width: 122px;
          height: 122px;
        }
        .t-1 {
          font-size: 20px;
          margin-top: 20px;
        }
        .t-2 {
          font-size: 14px;
          font-weight: 400;
          margin-top: 20px;
        }
      }
    }
  }

  .container-4 {
    width: 1168px;
    margin: 0 auto;
    padding: 60px 0;
    .title {
      font-size: 34px;
      color: #3f4652;
      text-align: center;
    }
    .cont-1 {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .item {
        width: 221px;
        height: 105px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item:hover {
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }
    .cont-2 {
      margin-top: 16px;
      display: flex;
      .item {
        width: 221px;
        height: 105px;
        background-color: #ffffff;
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px 16px 0;
      }
    }
  }
}
</style>